import React, {useState} from "react"
import styled, { keyframes }  from "styled-components"
import arrow from "../images/arrow-white.svg"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AlertMessage = styled.div`
  animation: ${fadeIn} 1s ease-out;
  padding: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'green'};
  color: white;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
`;

const LoaderContainer = styled.div`
  width: 40px;
  height: 35px;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #a7dcc7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${rotate} 2s linear infinite;
`;




const Title = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 56px;
  color: #0a0908;
  margin: 0 0 60px;
  @media (max-width: 568px) {
    font-size: 24px;
    margin-bottom: 23px;
    text-align: left;
  }
`
const SignUpForm = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 120px 0;
  form {
    margin: 0;
  }
  .txt {
    border: 1px solid #1a1919;
    background: #fff;
    color: #aaa;
    font-size: 16px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    line-height: 30px;
    margin: 0 0 20px;
    outline: none;
  }
  .holder {
    display: flex;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex-shrink: 0;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    border: 2px solid #8f8f8f;
  }
  @media (max-width: 568px) {
    padding: 60px 0 76px;
    .txt {
      padding: 5px 15px;
      margin-bottom: 12px;
    }
  }
`

const SubmitBtn = styled.button`
  width: 120px;
  height: 60px;
  display: flex;
  flex-shrink: 0;
  padding: 0;
  border: none;
  align-self: flex-end;
  margin: 0 0 0 35px;
  background: #1a1919;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    display: block;
    margin: 0;
    width: 38px;
    height: 31px;
  }

  &:hover {
      background: #000;
      color: #fff;
  }
  @media (max-width: 568px) {
    width: 80px;
    height: 40px;
    align-self: flex-start;
    img {
      width: 24px;
      height: 17px;
    }
  }
`

const CheckboxField = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
`

const CheckboxWrapper = styled.div`
  height: 2rem;
  position: relative;
  border: 0.1rem solid black;
  flex: 0 0 2rem;
`

const Checkbox = styled.input`
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
`

const CheckboxFacade = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  background: black;
  transform: translate(-50%, -50%);
  display: none;

  ${/* sc-selector */ Checkbox}:checked + & {
    display: block;
  }
`

const CheckboxLabel = styled.div`
  line-height: 20px;
  font-size: 16px;
  margin-left: 16px;
  color: #1a1919;
  a {
    color: #1a1919;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 568px) {
    font-size: 11px;
    line-height: 16px;
  }
`

const Anchor = styled.a`
  color: white;
  text-decoration: underline;
`

function SignUpComponent() {
  const [email, setEmail] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('You have been successfully subscribed!');
  const [AlertBgColor, setAlertBgColor] = useState('green');

  async function createContact({
    email,
    nom,
    prenom
  }) {
  
    if (!email||!nom||!prenom) {
        setShowAlert(true);
        setAlertBgColor('red');
        setMessage('Please fill all fields.');
        setTimeout(() => setShowAlert(false), 3000);
        return;
    }
  
    const url = 'https://brevo-ynec.onrender.com/create-contact';
    const data = {
        email,
        listId: 8,
        attributes: {
            NOM: nom,
            PRENOM: prenom
        }
    };
    setDisabled(true);
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
  
    if (!response.ok) {
        setShowAlert(true);
        setAlertBgColor('red');
        setMessage('An error occurred, may be email already exist.');
        setTimeout(() => setShowAlert(false), 3000);
    } else {
      setShowAlert(true);
      setAlertBgColor('green');
      setMessage('You have been successfully subscribed!');
      setNom('');
      setPrenom('');
      setEmail('');
      setTimeout(() => setShowAlert(false), 3000);
    }
    setDisabled(false);
  
    const responseData = await response.json();
    return responseData;
  }

  return (
    <SignUpForm>
      {
        showAlert?
          <AlertMessage backgroundColor={AlertBgColor}>
            {message}
          </AlertMessage>:null
      }
      <Title>Sign up for Alter news</Title>
        <input
          type="text"
          name="FNAME"
          className="txt"
          id="mce-FNAME"
          placeholder="First name"
          onChange={(e) => setPrenom(e.target.value)}
        />
        <input
          type="text"
          name="LNAME"
          className="txt"
          id="mce-LNAME"
          placeholder="Last name"
          onChange={(e) => setNom(e.target.value)}
        />
        <input
          type="email"
          name="EMAIL"
          className="txt required email"
          id="mce-EMAIL"
          placeholder="Your email address"
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="holder">
          <div>
            <CheckboxField htmlFor="gdpr_205">
              <CheckboxWrapper>
                <Checkbox
                  type="checkbox"
                  id="gdpr_205"
                  name="gdpr[205]"
                  defaultChecked="Y"
                  defaultValue="checked"
                />
                <CheckboxFacade />
              </CheckboxWrapper>
              <CheckboxLabel>
                By signing up I agree to the use of my personal data as
                described in our <Anchor to="/">Privacy Policy</Anchor>.
              </CheckboxLabel>
            </CheckboxField>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_f6104507653206db0b0ff6596_b4adece022"
              tabIndex="-1"
              defaultValue=""
            />
          </div>
          {
            disabled?
              <LoaderContainer>
                <Loader />
              </LoaderContainer>:
              <SubmitBtn onClick={()=>{
                  createContact({
                    email,
                    nom,
                    prenom
                  }).then(data => {
                    console.log('Contact created:', data);
                  }).catch(error => {
                    console.error('Error:', error);
                  });
            }}>
              <img src={arrow} alt="" />
          </SubmitBtn>}
        </div>

    </SignUpForm>
  )
}
export default SignUpComponent
