import React, { useEffect } from 'react'
import styled from 'styled-components'

import logo from "../images/logo-black.svg"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    
    top: 0;
    left: 0;

    z-index: 10000;
    
    width: 100vw;
    height: 100vh;

    background-color: #fff;
`

const ComingSoon = () => {

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'fixed'
    }, [])

    return (
        <Wrapper>
            <img src={logo} alt='kom schonn alter' width="100" height="94" style={{ 'marginBottom': '75px' }}/>
            <p>New website and extra Das Festival tickets 2021 coming soon!</p>
        </Wrapper>
    )
}

export default ComingSoon
