import React, { useEffect } from "react"
import styled from "styled-components"
import Marquee from "react-fast-marquee"

const WarningBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    background: black;
    font-family: Paralucent;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    padding-top: 5px;
    
    .marquee {
        span {
            margin-right: 5px;
        }
    }

    .overlay {
        display: none;
    }
`

function WarningBarComponent() {
  return (
    <WarningBar>
        <Marquee>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
            <span>NO KIDS ON THE DANCEFLOOR</span>
        </Marquee>
    </WarningBar>
  )
}
export default WarningBarComponent
