import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SocialsNav from "../components/SocialsNav"
import MobileNav from "../components/MobileNav"

import logo from "../images/logo-black.svg"

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 60px 50px 0 20px;
  margin: 0 auto 90px;
  max-width: 1220px;
  @media (max-width: 768px) {
    padding-right: 10px;
    padding-left: 10px;
    .holder {
      display: none;
    }
  }
  @media (max-width: 568px) {
    margin-bottom: 25px;
    padding-left: 22px;
    padding-top: 38px;
  }
`

const Logo = styled(Link)`
  flex-shrink: 0;
  img {
    margin-bottom: 0;
    display: block;
    width: 100%;
    height: auto;
  }
  @media (max-width: 568px) {
    width: 50px;
  }
`

const Socials = styled(SocialsNav)`
  float: right;
  padding-bottom: 25px;
`
const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  clear: right;
  a {
    margin-left: 58px;
    color: #000;
    &:first-child {
      margin-left: 0;
    }
  }
`

function HeaderComponent({ siteTitle }) {
  return (
    <Header>
      <Logo to="/">
        <img src={logo} alt={siteTitle} width="100" height="94" />
      </Logo>
      <div className="holder">
        <Socials></Socials>
        <Nav>
          <Link title="Home" to="/">
            Home
          </Link>
          <Link title="FAQ" to="/faq">
            FAQ
          </Link>
          <Link title="Gallery" to="/gallery">
            Gallery
          </Link>
        </Nav>
      </div>
      <MobileNav></MobileNav>
    </Header>
  )
}

export default HeaderComponent
