/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import WarningBar from "../components/WarningBar"
import Container from "../components/styles/Container"
import SignUpForm from "../components/SignUpForm"
import ComingSoon from "../components/ComingSoon"
import ShoppingCart from "../components/ShoppingCart"
import "../styles/layout.scss"

const Layout = ({ noContainer, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { NODE_ENV } = process.env

  useEffect(() => {
    const guid = "ec187407-d664-457a-98d6-94af6a39827f"

    if (typeof window !== "undefined") {
      //   if (window.OtShopWidget) return

      window.OtShopWidget.init(`https://shop.eventix.io/${guid}`, guid)

      if (window.fbq != null) {
        window.fbq("track", "PageView")
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="oqOtURk-l48VAynM37H0g6s9APGL2vdQXu7YMAucCZo" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title} />
      {noContainer ? (
        <>
          <main>{children}</main>
          <Container>
            <SignUpForm />
            <Footer />
          </Container>
        </>
      ) : (
        <Container>
          <main>{children}</main>
          <SignUpForm />
          <Footer />
        </Container>
      )}
      <WarningBar />
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
