import React, { useState } from "react"
import styled from "styled-components"
import cart from "../images/shopping-cart.svg"
import calendar from "../images/calendar.svg"
import arrow from "../images/arrow.svg"

const Checkout = styled.div`
  width: 80px;
  height: 80px;
  position: fixed;
  right: 40px;
  bottom: 40px;
  @media (max-width: 460px) {
    right: 10px;
    bottom: 10px;
    width: 40px;
    height: 40px;
  }
`

const Button = styled.button`
  cursor: pointer;
  display: block;
  width: 80px;
  height: 80px;
  border: 0;
  padding: 0;
  background: #1a1919 url(${cart}) no-repeat center;
  @media (max-width: 460px) {
    width: 40px;
    height: 40px;
    background-size: 60% 60%;
  }
`
const Cart = styled.div`
  position: absolute;
  bottom: 96px;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #1a1919;
  width: 400px;
  @media (max-width: 460px) {
    bottom: 50px;
    width: calc(100vw - 20px);
  }
`
const Header = styled.div`
  background: #1a1919;
  padding: 13px 16px;
  color: #fff;
`

const Title = styled.h3`
  font-size: 16px;
  margin: 0 0 3px;
  font-weight: 600;
`

const Date = styled.em`
  font-style: normal;
  padding-left: 24px;
  font-size: 14px;
  background: url(${calendar}) no-repeat 0 50%;
`
const ToggleTickets = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 18px;
  font-size: 24px;
  color: #1a1919;
  margin: 0;
  border: 0;
  cursor: pointer;
  width: 100%;
  align-items: center;
  img {
    margin: 0;
    transform: rotate(90deg);
    &.opened {
      transform: rotate(-90deg);
    }
  }
`
const TickerWrapper = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:flex-start;
  padding: 16px 18px;
`

const TicketType = styled.h4`
  color: #1a1919;
  font-size: 16px;
  margin: 0 0 2px;
  font-weight: bold;
`
const TicketPrice = styled.h5`
  display: block;
  color: #1a1919;
  font-size: 12px;
  margin: 0;
  font-weight: normal;
`
const CountButton = styled.button`
  border: 1px solid #1a1919;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 22px;
  height: 24px;
  display: flex;
  justify-content:center;
  align-items:center;
  font-size: 16px;
`

const TicketsAmount = styled.span`
  margin: 0 auto;
  line-height: 24px;
`

const TicketsPrice = styled.span`
  text-align: right;
  margin-bottom: 12px;
`

const Total = styled.div`
  display: flex;
  flex-direction:column;
  justify-content:flex-end;
  font-size: 16px;
  font-weight: bold;
`

const Buttons = styled.div`
  display: flex;
  width: 82px;
`

const Footer = styled.footer`
  padding: 16px;
  border-top: 1px solid #1a1919;
`
const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
  strong{
    font-size: 32px;
  }
  div{
    text-align:right;
    display: flex;
    flex-direction:column;
    justify-content:flex-start;
    font-size: 12px;
    line-height: 1;
    padding-right: 15px;
    padding-bottom: 16px;
    strong{
      font-size: 16px;
    }
  }
`

const Btn = styled.button`
  font-size: 16px;
  color: #fff;
  background: #1a1919;
  border: 0;
  padding: 0;
  width: 100%;
  line-height: 48px;
  cursor: pointer;
`

const TicketsQtyBadge = styled.span`
  background: red;
  border-radius:50%;
  color: #fff;
  width: 24px;
  line-height: 24px;
  position: absolute;
  right: -12px;
  top: -12px;
`

const CheckboxField = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
`

const CheckboxWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  border: 0.1rem solid black;
`

const Checkbox = styled.input`
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
`

const CheckboxFacade = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: black;
  transform: translate(-50%, -50%);
  display: none;

  ${/* sc-selector */ Checkbox}:checked + & {
    display: block;
  }
`

const CouponHolder = styled.div`
  padding: 18px;
  border-top: 1px solid #1a1919;
  h4{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }
`

const CheckboxLabel = styled.div`
  line-height: 20px;
  font-size: 14px;
  margin-left: 8px;
  color: #1a1919;
  a {
    color: #1a1919;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 568px) {
    font-size: 11px;
    line-height: 16px;
  }
`

function ShoppingCart() {
  const [opened, setOpen] = useState(false)
  const [openedTickets, setTickets] = useState(true)
  const [ticketPrice, setTicketsPrice] = useState(0)
  const [ticketsQty, setTicketsQty] = useState(0)
  const ticketCost = 15.00
  return (
    <Checkout>
      <Button
        onClick={() => {
          setOpen(!opened)
        }}
        onKeyDown={() => {
          setOpen(!opened)
        }}
      >
        {ticketsQty > 0 && <TicketsQtyBadge>{ticketsQty}</TicketsQtyBadge>}
      </Button>
      {opened && (
        <Cart>
          <Header>
            <Title>online clubnight @ Paradiso w/ dimitri &amp; dj isis</Title>
            <Date>12/12/20 20:00 - 12/12/20 23:00</Date>
          </Header>
          <ToggleTickets
            onClick={() => {
              setTickets(!openedTickets)
            }}
            onKeyDown={() => {
              setTickets(!openedTickets)
            }}
          >
            <span>Tickets</span>
            <img
              className={openedTickets ? "opened" : ""}
              src={arrow}
              alt="Tickets"
              width="16"
              height="16"
            />
          </ToggleTickets>
          {openedTickets && (
            <div style={{ borderTop: "1px solid #1a1919" }}>
              <TickerWrapper>
                <div>
                  <TicketType>Early Bird ticket</TicketType>
                  <TicketPrice>
                    € {ticketCost.toFixed(2)} + € 0.00 fee
                  </TicketPrice>
                </div>
                <Total>
                  <TicketsPrice>€ {ticketPrice.toFixed(2)}</TicketsPrice>
                  <Buttons>
                    <CountButton
                      disabled={ticketsQty < 1}
                      onClick={() => {
                        setTicketsPrice(ticketPrice - ticketCost)
                        setTicketsQty(ticketsQty - 1)
                      }}
                      onKeyDown={() => {
                        setTicketsPrice(ticketPrice - ticketCost)
                        setTicketsQty(ticketsQty - 1)
                      }}
                    >
                      -
                    </CountButton>
                    <TicketsAmount>{ticketsQty}</TicketsAmount>
                    <CountButton
                      onClick={() => {
                        setTicketsPrice(ticketPrice + ticketCost)
                        setTicketsQty(ticketsQty + 1)
                      }}
                      onKeyDown={() => {
                        setTicketsPrice(ticketPrice + ticketCost)
                        setTicketsQty(ticketsQty + 1)
                      }}
                    >
                      +
                    </CountButton>
                  </Buttons>
                </Total>
              </TickerWrapper>
            </div>
          )}
          <CouponHolder>
            <h4>Got a coupon?</h4>
            <CheckboxField htmlFor="eventix_terms">
              <CheckboxWrapper>
                <Checkbox
                  type="checkbox"
                  id="eventix_terms"
                  name="eventix_terms"
                  defaultChecked="N"
                />
                <CheckboxFacade />
              </CheckboxWrapper>
              <CheckboxLabel>
                I agree to the terms of service of Eventix
              </CheckboxLabel>
            </CheckboxField>
          </CouponHolder>
          <Footer>
            <FooterWrapper>
              <div>
                <strong>Total</strong>
                <span>Incl. € 0.00 Booking fee</span>
              </div>
              <strong>€ {ticketPrice.toFixed(2)}</strong>
            </FooterWrapper>
            <Btn>Next</Btn>
          </Footer>
        </Cart>
      )}
    </Checkout>
  )
}
export default ShoppingCart
