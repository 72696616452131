/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || site.siteMetadata.keywords
  const metaTitle = title || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={metaTitle ? `%s | ${metaTitle}` : null}
      meta={[
        {
            name: `viewport`,
            content: `width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no`,
        },
        // {
        //     property: `image`,
        //     content: metaImage,
        // },
        {
            name: `description`,
            content: metaDescription,
        },
        {
            name: `keywords`,
            content: metaKeywords,
        },
        {
            property: `og:title`,
            content: metaTitle,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        // {
        //     property: `og:image`,
        //     content: metaImage,
        // },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: 'twitter:card', 
            content: `summary_large_image`
        },
        {
            name: `twitter:title`,
            content: metaTitle,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
        {
            name: `twitter:keywords`,
            content: metaKeywords,
        },
        // {
        //     property: `twitter:image`,
        //     content: metaImage,
        // },
        // {
        //     property: 'og:image:width',
        //     content: '1100'
        // },
        // {
        //     property: 'og:image:height',
        //     content: '619'
        // }
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  title: ``,
  description: ``,
  keywords: ``
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
