import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { use100vh } from 'react-div-100vh'

import SocialsNav from "../components/SocialsNav"
import arrow from '../images/arrow.svg'
import logo from "../images/logo-black.svg"

const CloseNav = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  position: absolute;
  top: 55px;
  right: 10px;
  img {
    display: block;
    margin: 0;
    width: 38px;
    height: 31px;
  }
`

const Logo = styled.span`
  position: absolute;
  top: 30px;
  left: 30px;
  img {
    margin-bottom: 0;
    display: block;
    width: 100%;
    height: auto;
  }
`

const OpenNav = styled.button`
  display: none;
  position: relative;
  width: 32px;
  height: 22px;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  &:before,
  &:after,
  span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background: #000;
  }
  &:before,
  &:after {
    content: "";
    top: 10px;
  }
  &:after {
    top: auto;
    bottom: 0;
  }
  @media (max-width: 768px) {
    display: block;
  }
  @media (max-width: 568px) {
    margin-top: 13px;
  }
`

const Frame = styled.div`
  width: 90vw;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  /* min-height: 100vh; */
  border-left: 1px solid #1a1919;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: -20px 0 20px rgba(0, 0, 0, 0.25);
`

const MobileSocialsNav = styled(SocialsNav)`
  position: absolute;
  bottom: 42px;
  right: 16px;
  li {
    margin: 0 0 0 13px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  font-size: 25px;
  text-align: right;
  padding-right: 24px;
  a {
    margin-bottom: 30px;
    color: #000;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const MobileNav = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  /* bottom: 0;
  right: 0; */
  background: rgba(26, 25, 25, 0.4);
  z-index: 100;
`

const Triangle = styled.span`
  width: 0;
  height: 0;
  border-top: 400px solid transparent;
  border-right: 400px solid ${props => (props.color ? props.color : "#a7dbc7")};
  border-bottom: 400px solid transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`

const MobileNavComponent = () => {
  const [modalShow, setModalShow] = React.useState(false)
  const fullHeight = use100vh()
  return (
    <>
      <OpenNav
        onClick={() => setModalShow(true)}
        onKeyDown={() => setModalShow(true)}
      >
        <span></span>
      </OpenNav>
      {modalShow && (
        <MobileNav style={{ height: fullHeight ? fullHeight : '100vh' }}>
          <Frame>
            <Logo>
              <img src={logo} alt="Komm Schon Alter" width="100" height="94" />
            </Logo>
            <CloseNav
              className="closeNav"
              onClick={() => setModalShow(false)}
              onKeyDown={() => setModalShow(false)}
            >
              <img src={arrow} alt="" />
            </CloseNav>
            <Nav>
              <Link title="Home" to="/">
                Home
              </Link>
              <Link title="FAQ" to="/faq">
                FAQ
              </Link>
              <Link title="Gallery" to="/gallery">
                Gallery
              </Link>
              {/* <Link title="Merchandise" to="/merchandise">
                Merchandise
              </Link> */}
            </Nav>
            <MobileSocialsNav></MobileSocialsNav>
            <Triangle></Triangle>
          </Frame>
        </MobileNav>
      )}
    </>
  )
}

export default MobileNavComponent
