import * as React from "react"
import styled from "styled-components"

import spotify from "../images/spotify-icon.svg"
import instagram from "../images/instagram-icon.svg"
import facebook from "../images/facebook-icon.svg"
import pinterest from "../images/pinterest-icon.svg"

const SocialNav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-left: 24px;
    margin-bottom: 25px;
    &:first-child {
      margin-left: 0;
    }
  }
  img {
    margin-bottom: 0;
    display: block;
  }
`

const SocialNavComponent = ({ size, style, className }) => {
  const icoSize = size ? size : "32"
  return (
    <SocialNav style={style} className={className}>
      <li>
        <a rel="nofollow" title="Spotify" href="https://open.spotify.com/user/myq3okva6mbrtwsbw4rwohrkt?si=9f7c9075cb054077">
          <img src={spotify} alt="Spotify" width={icoSize} height={icoSize} />
        </a>
      </li>
      <li>
        <a rel="nofollow" title="Instagram" href="https://www.instagram.com/kommschonalter24/">
          <img
            src={instagram}
            alt="Instagram"
            width={icoSize}
            height={icoSize}
          />
        </a>
      </li>
      <li>
        <a rel="nofollow" title="Facebook" href="https://www.facebook.com/kommschonAlter24/">
          <img src={facebook} alt="Facebook" width={icoSize} height={icoSize} />
        </a>
      </li>
      <li>
        <a rel="nofollow" title="Pinterest" href="https://nl.pinterest.com/kommschonalter24/_saved/">
          <img
            src={pinterest}
            alt="Pinterest"
            width={icoSize}
            height={icoSize}
          />
        </a>
      </li>
    </SocialNav>
  )
}

export default SocialNavComponent
