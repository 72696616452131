import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SocialsNav from "../components/SocialsNav"

import BudLogo from "./../images/bud-logo.svg"

const Footer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #808080;
  font-size: 16px;
  padding-bottom: 60px;
  & > * {
    flex: 1;
    display: flex;
  }
  p {
    margin: 0 15px;
    justify-content: center;
  }
  a {
    color: #1a1919;
  }
  nav {
    margin-left: auto;
    flex-direction: column;
    text-align: right;
  }
  .socials-nav {
    li {
      margin-left: 32px;
      margin-bottom: 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  @media (max-width: 1024px) {
    p {
      order: 2;
      margin-right: 0;
    }
    .socials-nav {
      order: 1;
    }
    nav {
      order: 0;
      flex: auto;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      a {
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 700px) {
    .socials-nav {
      padding-top: 15px;
      padding-bottom: 30px;
      flex: auto;
      width: 100%;
      text-align: center;
      justify-content: center;
      li {
        margin-left: 14px;
      }
      img {
        width: 32px;
        height: 32px;
      }
    }
    p {
      font-size: 14px;
      margin: 0;
    }
  }
  @media (max-width: 568px) {
    display: block;
    padding-bottom: 35px;
  }
`

const Center = styled.p`
  text-align: center;
`

const Right = styled.div`
  text-align: center;
`

const Bud = styled.img`
  width: 150px;

  margin: 0 auto;

  @media (max-width: 568px) {
    margin: 25px auto 0 auto;
  }
`

function FooterComponent() {
  return (
    <Footer>
      <SocialsNav
        className="socials-nav"
        size="40"
        style={{ marginRight: "auto" }}
      ></SocialsNav>
      <Center>
        Copyright © {new Date().getFullYear()} Chasing the Hihat Events B.V.
      </Center>
      <Right>
        <Bud src={BudLogo} alt="" />
      </Right>
    </Footer>
  )
}

export default FooterComponent
