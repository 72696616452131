import styled from "styled-components"
import pageBg from "../../images/page-bg.png"
import pageBgMobile from "../../images/page-bg-mobile.png"

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1160px;
  /* min-height: 100vh; */
  box-sizing: border-box;
  &:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background: url(${pageBg}) repeat 50% 0;
    opacity: 0.5;
  }
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
  @media (max-width: 768px) {
    &:after {
      background: url(${pageBgMobile}) repeat 85% 0;
    }
  }
`
export default Container
